import { useRef } from 'react';
import { clsx } from 'clsx';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';

import { Button } from '@dop-ui/react/shared/ui/button';
import { DeleteIcon, DragIcon } from '@daouoffice/ui/lib/icons/dop/24';
import * as DnDList from '@dop-ui/react/shared/ui/dnd/list';
import LinkItem from '../../../link-item';
import styles from '../organizer-shortcut-setting.module.css';

import type { ShortcutItemInfo, ShortcutItemType } from '../../../types';
import { useMessageDialog } from '@dop-ui/react/shared/ui/message-dialog';

export interface Props {
  index: number;
  item: ShortcutItemInfo;
  itemType: ShortcutItemType;
  onDelete: (uid: number, type: ShortcutItemType) => void;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    type: ShortcutItemType,
  ) => void;
}

export function SettingsMenuItem({
  index,
  item,
  itemType,
  onDelete,
  onChange,
}: Props) {
  const { t } = useTranslation();
  const { confirm } = useMessageDialog();
  const isAppItem = itemType === 'APP';

  const dragHandleRef = useRef<HTMLDivElement>(null);

  const handleClickDelete = async () => {
    if (
      await confirm(
        <div className="w-full">
          <h2 className="mb-3">
            {t('organizer.shortcut.setting.alert.delete.title')}
          </h2>
          <p className="mb-2" style={{ whiteSpace: 'pre-line' }}>
            {t('organizer.shortcut.setting.alert.delete')}
          </p>
        </div>,
      )
    ) {
      onDelete(item.uid, item.itemType);
    }
  };

  const linkItem = (size: number) => (
    <LinkItem
      id={item.uid}
      name={item.name}
      size={size}
      faviconUrl={item.icon}
    />
  );

  const preview = (
    <div className="flex">
      {linkItem(28)}
      <span className="ml-2 max-w-[120px] rounded-sm text-white bg-[#363636] overflow-hidden whitespace-nowrap text-ellipsis px-2 py-1">
        {item.name}
      </span>
    </div>
  );

  const offset = {
    x: '10px',
    y: '20px',
  };

  return (
    <DnDList.Item
      item={item}
      key={index}
      previewInfo={{ preview, offset }}
      dragHandleRef={dragHandleRef}
      className={styles.shortcut}
      styleSet={{
        'is-dragging-over': 'opacity-40',
      }}
    >
      <div className="flex items-center mr-1" ref={dragHandleRef}>
        <DragIcon size={24} />
      </div>
      <div className="w-[40px] h-[40px]">{linkItem(40)}</div>

      <input
        className={clsx('input_txt', { '!bg-gray-300': isAppItem })}
        type="text"
        title=""
        value={item.name}
        disabled={isAppItem}
        onChange={(e) => onChange(e, item.uid, item.itemType)}
      />

      <Button
        className="p-1 bg-transparent"
        onClick={() => void handleClickDelete()}
      >
        <DeleteIcon size={20} />
        <span className="blind">
          {t('organizer.shortcut.setting.button.close')}
        </span>
      </Button>
    </DnDList.Item>
  );
}

export default SettingsMenuItem;
