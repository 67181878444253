import fetch from '@dop-ui/react/shared/lib/fetch/client';

interface Params {
  productId: number;
  agreed: boolean;
}

export async function postLinkPlusTermsAgreement({
  productId,
  agreed,
}: Params) {
  const response = await fetch(
    `/api/linkplus/api/link/product/user/term?productId=${productId}&agreed=${agreed}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(''),
    },
  );

  // const resp = await response.json();
  // console.log('resp', response.json());

  if (!response.ok) {
    const responseJson = (await response.json()) as { message: string };
    throw new Error(responseJson.message);
  }
}
