import { clsx } from 'clsx';
import Button from '@dop-ui/react/shared/ui/button';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import { useOrganizerContext } from '../../../Context';
import {
  CreateAppShortcutInput,
  mapper,
  postAppShortcut,
} from '../api/createShortcutQuery';
import {
  useToastMessage,
  ToastMessageTemplates as Templates,
} from '@dop-ui/react/shared/ui/toast';
import { QUERY_KEY as getOrganizerQueryId } from '../../../apis/getOrganizerQuery';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import LinkItem from '../../../link-item';
import type { SizeType } from '../../../types';
import type { AppType } from '../types';
import styles from '../shortcut.module.css';
import {
  QUERY_KEY,
  getLinkPlusTermsAgreement,
} from '../api/getLinkPlusTermsAgreement';
import { useState } from 'react';
import TermAgreementDialog from './term-agreement-dialog';

const getActionItemSize = (sizeType: SizeType) => {
  if (sizeType === 'tiny') {
    return [16, 'ic_small', 'small'];
  } else if (sizeType === 'small') {
    return [18, 'ic_small', 'small'];
  } else if (sizeType === 'medium') {
    return [24, 'ic_medium', 'medium'];
  } else if (sizeType === 'large') {
    return [36, 'ic_large', 'large'];
  } else {
    return [48, 'ic_xl', undefined];
  }
};

interface Props {
  type: string;
  appItem: AppType;
}

function AppItem({ type, appItem }: Props) {
  const { t } = useTranslation();
  const ctx = useOrganizerContext();
  const queryClient = useQueryClient();

  const { info } = useToastMessage();
  const { refetch } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => await getLinkPlusTermsAgreement(appItem.id),
    enabled: false,
  });

  const [sizeNumber] = getActionItemSize(appItem.size);
  const [isLinkPlusTermsAgreementOpen, setIsLinkPlusTermsAgreementOpen] =
    useState(false);

  const handleOnSuccess = () => {
    void queryClient.invalidateQueries({
      queryKey: [getOrganizerQueryId],
    });
    info(<Templates.Basic description={t('organizer.toast.add')} />);
  };

  const postAppShortcutMutaion = useMutation({
    mutationFn: (appData: CreateAppShortcutInput) => postAppShortcut(appData),
    onSuccess: () => {
      handleOnSuccess();
    },
    onError(error) {
      console.log(error);
    },
  });

  const shortcutListByType = ctx.shortcutItems.filter((item) => {
    return item.appType === type;
  });

  const isAdded = shortcutListByType.some((item) => {
    return item.itemId === appItem.id;
  });

  const handleClickAdd = async (appItem: AppType) => {
    if (type === 'LINK_PLUS') {
      try {
        const { data } = await refetch();
        if (!data) {
          setIsLinkPlusTermsAgreementOpen(true);
        } else {
          postAppShortcutMutaion.mutate(mapper(appItem, type));
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      postAppShortcutMutaion.mutate(mapper(appItem, type));
    }
  };

  return (
    <>
      <li className={styles.AppItemContainer}>
        <LinkItem
          className={styles.AppItemIcon}
          id={typeof appItem.id === 'string' ? 0 : appItem.id}
          name={appItem.name}
          size={sizeNumber as number}
          faviconUrl={appItem.thumbnail}
        />

        <div className={styles.AppItemInfo}>
          <p className={styles.AppItemName}>{appItem.name}</p>
          <p className={styles.AppItemDescription}>{appItem.desc}</p>
        </div>

        <Button
          size="small"
          shape="rect"
          variant="outline"
          colorset="level1"
          disabled={isAdded}
          className={clsx({
            '!border-[#d8d8d8] !text-[#5b5b5b]': isAdded,
          })}
          onClick={() => void handleClickAdd(appItem)}
        >
          {isAdded ? t('organizer.added.button') : t('organizer.add.button')}
        </Button>
      </li>
      <TermAgreementDialog
        isOpen={isLinkPlusTermsAgreementOpen}
        setIsOpen={setIsLinkPlusTermsAgreementOpen}
        linkPlusItem={appItem}
        postAppShortcutMutaion={postAppShortcutMutaion}
      />
    </>
  );
}

export default AppItem;
