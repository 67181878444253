import { useTranslation } from '../../../../lib/i18n/client/useTranslation';
import { useOrganizerAtom } from '../../Provider';
import { GPT_TRAINER_DOMAIN_URL } from '../../constants';
import PanelHeader from '../panel-header';
import styles from '../../organizer-panel.module.css';
import { useState } from 'react';

export function OrganizerChatbot() {
  const { t } = useTranslation();

  const [, setOrganizerAtom] = useOrganizerAtom();

  const GPT_TRAINER_URL = `${window.location.protocol}${GPT_TRAINER_DOMAIN_URL}`;

  const handleOnClose = () => {
    setOrganizerAtom((prev) => ({
      ...prev,
      organizerState: 'default',
      panelState: 'none',
    }));
  };

  const [isLoaded, setIsLoaded] = useState(false);

  const handleOnLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div className={styles.Wrapper}>
      <PanelHeader
        title={t('organizer.chatbot.title')}
        onClickClose={handleOnClose}
      />
      {!isLoaded && (
        <div className="flex w-full h-full items-center justify-center">
          <p>Loading..</p>
        </div>
      )}
      <iframe
        title="chatbot"
        onLoad={handleOnLoad}
        src={GPT_TRAINER_URL}
        width="100%"
        height={isLoaded ? '100%' : '0px'}
        allow="clipboard-read; clipboard-write"
      ></iframe>
    </div>
  );
}

export default OrganizerChatbot;
