import { MemoRecentlySearchType } from '../apis/getMemoRecentlySearchList';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteMemoRecentlySearch } from '../apis/deleteMemoRecentlySearch';
import * as getMemoRecentlySearchList from '../apis/getMemoRecentlySearchList';
import { useMemoContext } from '../Context';
import { Button } from '@dop-ui/react/shared/ui/button';
import memoStyles from '../memo.module.css';
import { MagnifyingIcon } from '@dop-ui/icons/react/dop/24';
import { XMarkCircleIcon } from '@dop-ui/icons/react/dop/16';

interface Props {
  searchItem: MemoRecentlySearchType;
}

export function RecentlySearchItem({ searchItem }: Props) {
  const queryClient = useQueryClient();

  const { memoContext, setMemoContext } = useMemoContext();

  const deleteRecentlySearchMutaion = useMutation({
    mutationFn: (id: number) => deleteMemoRecentlySearch(id),
    onSuccess() {
      void queryClient.invalidateQueries({
        queryKey: [getMemoRecentlySearchList.QUERY_KEY],
      });
    },
    onError(error) {
      console.log(error.message);
    },
  });

  const handleClickResult = () => {
    setMemoContext({
      ...memoContext,
      keyword: searchItem.keyword,
    });
  };

  const handleClickXMark = (e?: React.MouseEvent<HTMLElement>) => {
    if (!e) {
      return;
    }
    e.stopPropagation();
    deleteRecentlySearchMutaion.mutate(searchItem.id);
  };

  return (
    <li
      className="flex items-center justify-center min-h-[36px] p-2 gap-2 cursor-pointer rounded-lg hover:bg-[#F2F2F2]"
      onClick={handleClickResult}
      aria-hidden
    >
      <MagnifyingIcon className="text-[#AAAAAA]" size={20} />
      <span className="flex-grow">{searchItem.keyword}</span>
      <Button className={memoStyles.MemoSvgBtn} onClick={handleClickXMark}>
        <i className="icon w-[20px] h-[20px]">
          <XMarkCircleIcon size={15} className="text-[#AAAAAA] p-[0.5px]" />
        </i>
      </Button>
    </li>
  );
}

export default RecentlySearchItem;
