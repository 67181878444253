export interface Response {
  code: string;
  message: string;
  data: boolean;
}

export const QUERY_KEY = 'organizer-link-plus-terms-agreement';

export async function getLinkPlusTermsAgreement(
  productId: number,
): Promise<boolean> {
  try {
    const response = await fetch(
      `/api/linkplus/api/link/product/user/term?productId=${productId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Referer-Info': window.location.host,
        },
      },
    );

    if (!response.ok) {
      throw new Error('getLinkPlusList 오류 발생.');
    }

    const resData = (await response.json()) as Response;

    return resData.data;
  } catch (error) {
    console.log('getLinkPlusTermsAgreement 오류 발생', error);
    return false;
  }
}
