import * as Dialog from '@dop-ui/react/shared/ui/dialog';
import { AppType } from '../types';
import { Dispatch, SetStateAction, useState } from 'react';
import './term.css';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postLinkPlusTermsAgreement } from '../api/post-link-plus-terms-agreement';
import { QUERY_KEY as LINK_PLUS_TERMS_QUERY_KEY } from '../api/getLinkPlusTermsAgreement';
import { useToastMessage } from '@dop-ui/react/shared/ui/toast';
import { useTranslation } from 'react-i18next';
import { CreateAppShortcutInput, mapper } from '../api/createShortcutQuery';

interface Props {
  isOpen: boolean;
  linkPlusItem: AppType;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  postAppShortcutMutaion: {
    mutate: (appData: CreateAppShortcutInput) => void;
  };
}

export function TermAgreementDialog({
  isOpen,
  linkPlusItem,
  setIsOpen,
  postAppShortcutMutaion,
}: Props) {
  const { t } = useTranslation();
  const toaster = useToastMessage();
  const queryClient = useQueryClient();

  const [isAgree, setIsAgree] = useState(false);

  const postLinkPlusTermsAgreementMutaion = useMutation({
    mutationFn: async () =>
      await postLinkPlusTermsAgreement({
        productId: linkPlusItem.id,
        agreed: isAgree,
      }),
    onSuccess: async () => {
      setIsAgree(false);
      await queryClient.invalidateQueries({
        queryKey: [LINK_PLUS_TERMS_QUERY_KEY],
      });
      postAppShortcutMutaion.mutate(mapper(linkPlusItem, 'LINK_PLUS'));
      setIsOpen(false);
    },
    onError: (e) => {
      if (e) {
        toaster.warning(e.message);
      } else {
        toaster.warning(t('globalconfig.common.toastMessage.fail'));
      }
    },
  });

  const handleClickAgree = () => {
    if (!isAgree) {
      toaster.info('개인정보 제3자 제공 약관을 동의해주세요.');
      return;
    }
    postLinkPlusTermsAgreementMutaion.mutate();
  };

  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={() => {
        setIsAgree(false);
        setIsOpen(false);
      }}
    >
      <Dialog.Primitives.Portal>
        <Dialog.Content
          className="w-[500px] bg-white px-[24px] py-[24px] rounded-[16px] break-words"
          useAutoClose={false}
        >
          <div className="layer_linkplus_apply">
            <div className="layer_header">
              <h1>개인정보 제3자 제공 동의</h1>
              <p className="desc">
                {`다우기술(회사)은 Link+(링크플러스)에 게시된 제휴서비스 연동을 위해 다음과 같이 개인정보를 ${linkPlusItem.name}에 제공합니다.`}
              </p>
            </div>

            <div className="layer_body">
              <p className="tit">1. 개인정보를 제공받는 자</p>
              <p className="desc">{linkPlusItem.name}</p>

              <p className="tit">2. 개인정보 제공 목적</p>
              <p className="desc">Link+(링크플러스)를 통한 SSO 연동</p>

              <p className="tit">3. 개인정보 제공 항목</p>
              <p className="desc">이름, 이메일, 연락처</p>

              <p className="tit">
                4. 개인정보를 제공 받는 자의 보유 및 이용기간
              </p>
              <p className="desc">
                수집·이용 동의일로부터 12개월 (단, 요청 시 삭제)
              </p>

              <hr />

              <p className="desc">
                동의를 거부하시는 경우 Link+(링크플러스)를 통한 제휴 서비스
                연동이 제한될 수 있습니다.
              </p>
            </div>

            <ul className="list_agree">
              <li>
                <input
                  className="ipt"
                  type="checkbox"
                  id="ipt_linkplus_agree"
                  checked={isAgree}
                  onChange={() => setIsAgree(!isAgree)}
                />
                <label htmlFor="ipt_linkplus_agree">
                  개인정보 제3자 제공 동의 (선택)
                </label>
              </li>
            </ul>
            <div className="layer_footer">
              <button
                className={`linkplus_apply_btn ${
                  !isAgree ? 'linkplus_apply_btn_disabled' : ''
                }`}
                style={{ display: 'inline-block' }}
                onClick={handleClickAgree}
              >
                <span className="">동의하기</span>
              </button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Primitives.Portal>
    </Dialog.Root>
  );
}

export default TermAgreementDialog;
