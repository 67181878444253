export const SHORTCUT_ITEM_HEIGHT = 54;

//BOTTOM 영역에 unfold, setting, chatbot 세 개
export const SETTING_AREA_HEIGHT = SHORTCUT_ITEM_HEIGHT * 3;

//SETTING AREA 이외에 아이콘 요소가 6개 들어감.
export const ORGANIZER_MIN_HEIGHT =
  6 * SHORTCUT_ITEM_HEIGHT + SETTING_AREA_HEIGHT;

export const GPT_TRAINER_URL = 'http://go.daouoffice.co.kr/GPTdo_dop';
export const GPT_TRAINER_DOMAIN_URL = '//go.daouoffice.co.kr/GPTdo_dop';
