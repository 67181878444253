import { clsx } from 'clsx';
import { useOrganizerContext } from '../Context';
import { isSettingItemsChanged } from '../lib/utils';
import { useChangePanel, useOrganizerAtom } from '../Provider';
import { useTranslation } from '../../../lib/i18n/client/useTranslation';
import { useMessageDialog } from '@dop-ui/react/shared/ui/message-dialog';

import LinkButton from '../link-button';
import { getDefaultAppIcon } from '../default-menu-list/icons';

import styles from '../organizer-body.module.css';
import type { PanelState } from '../types';

interface Props {
  id: PanelState;
  name: string;
  hasBackground: boolean;
}
function PanelMenu({ id, name, hasBackground }: Props) {
  const [organizerAtom] = useOrganizerAtom();
  const changePanel = useChangePanel();
  const { settingsItems, shortcutItems, setSettingsItems } =
    useOrganizerContext();
  const { t } = useTranslation();
  const { confirm } = useMessageDialog();

  const isCurrentApp = (appName: PanelState) => {
    return appName === organizerAtom.panelState;
  };

  const handleConfirm = async () => {
    if (
      await confirm(
        <div className="w-full">
          <h2 className="mb-3">{t('organizer.setting.alert.cancel.title')}</h2>
          <p className="mb-2" style={{ whiteSpace: 'pre-line' }}>
            {t('organizer.setting.alert.cancel')}
          </p>
        </div>,
      )
    ) {
      return true;
    }
    return false;
  };

  const handleOnClickApp = async (appName: PanelState) => {
    if (organizerAtom.panelState === 'setting') {
      if (
        isSettingItemsChanged(settingsItems, shortcutItems) &&
        !(await handleConfirm())
      ) {
        return;
      }
      setSettingsItems(shortcutItems);
    }
    changePanel(appName);
  };
  return (
    <LinkButton
      className={styles.link_list}
      id={id}
      key={name}
      name={name}
      onClick={() => void handleOnClickApp(id)}
    >
      <div
        className={clsx(styles.btn_panel_app, {
          'bg-[#08A7BF]': hasBackground,
        })}
      >
        {getDefaultAppIcon(id)}
        {isCurrentApp(id) && <div className={styles.panel_active_bar}></div>}
      </div>
    </LinkButton>
  );
}
export default PanelMenu;
