import { useState } from 'react';
import { clsx } from 'clsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from '../../../../lib/i18n/client/useTranslation';

import { useOrganizerContext } from '../../Context';
import { useChangePanel } from '../../Provider';
import { SettingsMenuItemList } from './settings-menu-item-list';
import { QUERY_KEY as getOrganizerQueryId } from '../../apis/getOrganizerQuery';
import {
  ReArrangeInput,
  rearrangeShortcutQuery,
} from '../Shortcut/api/rearrangeShortcutListMutation';
import { isSettingItemsChanged } from '../../lib/utils';

import {
  ToastMessageTemplates as Templates,
  useToastMessage,
} from '@dop-ui/react/shared/ui/toast';
import { Button } from '@dop-ui/react/shared/ui/button';
import Blur from '../../shortcut-list/blur';
import styles from '../../organizer-panel.module.css';
import shortcutStyles from './organizer-shortcut-setting.module.css';
import type {
  IdentifiableShortcutItem,
  UpdatedLinkItem,
} from '../Shortcut/types';
import { useMessageDialog } from '@dop-ui/react/shared/ui/message-dialog';
import PanelHeader from '../panel-header';

export function SettingsMenu() {
  const queryClient = useQueryClient();
  const changePanel = useChangePanel();
  const { info } = useToastMessage();
  const { confirm } = useMessageDialog();

  const [deletedList, setDeletedLIst] = useState<IdentifiableShortcutItem[]>(
    [],
  );
  const { t } = useTranslation();
  const { shortcutItems, settingsItems, setSettingsItems } =
    useOrganizerContext();

  const handleOnError = (message: string) => {
    info(<Templates.Basic description={`${message}`} />);
  };

  const generateReArrangeInput = (): ReArrangeInput => {
    const updatedLinkList: UpdatedLinkItem[] = [];

    shortcutItems
      .filter((item) => item.itemType === 'LINK')
      .forEach((item) => {
        const updatedItem = settingsItems.find(
          (shortcut) =>
            shortcut.uid === item.uid && shortcut.name !== item.name,
        );
        if (updatedItem) {
          updatedLinkList.push({
            id: updatedItem.uid,
            link: updatedItem.name,
          });
        }
      });

    return {
      sortedList: settingsItems.map((item) => ({
        id: item.uid,
        type: item.itemType,
      })),
      deletedList,
      updatedLinkList,
    };
  };

  const saveMutation = useMutation({
    mutationFn: async () =>
      await rearrangeShortcutQuery(generateReArrangeInput()),
    onSuccess: (data) => {
      const { errors } = data;
      if (errors && errors[0]) {
        handleOnError(errors[0].message);
      } else {
        handleOnSaveSuccess();
      }
    },
    onError: (error) => {
      handleOnError(error.message);
    },
  });

  const handleOnSaveSuccess = () => {
    void queryClient.invalidateQueries({
      queryKey: [getOrganizerQueryId],
    });
    setDeletedLIst([]);
    changePanel('setting');
    info(
      <Templates.Basic
        description={t('organizer.shortcut.setting.toast.save')}
      />,
    );
  };
  const handleConfirm = async () => {
    if (
      await confirm(
        <div className="w-full">
          <h2 className="mb-3">{t('organizer.setting.alert.cancel.title')}</h2>
          <p className="mb-2" style={{ whiteSpace: 'pre-line' }}>
            {t('organizer.setting.alert.cancel')}
          </p>
        </div>,
      )
    ) {
      return true;
    }
    return false;
  };

  const handleOnClickCancel = async () => {
    if (
      isSettingItemsChanged(shortcutItems, settingsItems) &&
      !(await handleConfirm())
    ) {
      return;
    }
    setSettingsItems(shortcutItems);
    changePanel('setting');
  };

  // 앱 바로가기 전체삭제 기능 제거 요청에 따른 주석처리
  // const deleteAllMutation = useMutation({
  //   mutationFn: async () => await deleteAllShortcut(),
  //   onSuccess: () => {
  //     handleOnDeleteSuccess();
  //   },
  //   onError: (error) => {
  //     handleOnError(error.message);
  //   },
  // });

  // const handleOnClickDeleteAll = () => {
  //   if (confirm(t('organizer.shortcut.setting.alert.delete.all'))) {
  //     deleteAllMutation.mutate();
  //   }
  // };
  // const handleOnDeleteSuccess = () => {
  //   void queryClient.invalidateQueries({
  //     queryKey: [getOrganizerQueryId],
  //   });
  //   setOrganizerAtom((prev) => ({
  //     ...prev,
  //     settingMenuOpenState: false,
  //   }));
  //   info(
  //     <Templates.Basic
  //       description={t('organizer.shortcut.setting.toast.delete.all')}
  //     />,
  //   );
  // };

  return (
    <div className={styles.Wrapper}>
      <PanelHeader
        title={t('organizer.shortcut.setting')}
        onClickClose={() => void handleOnClickCancel()}
      />
      <div className={clsx(styles.Content)}>
        <div className={clsx(styles.ContentHeader, 'pt-6')}>
          <p className="text-[#878787] my-2">
            {t('organizer.shortcut.setting.desc')}
          </p>
        </div>
        <SettingsMenuItemList setDeletedItemList={setDeletedLIst} />
      </div>
      <div className={clsx(shortcutStyles.shortcut_buttons, 'group_btn half')}>
        <Blur className={shortcutStyles.blur} />
        <Button
          size="medium"
          shape="rect"
          variant="outline"
          colorset="level1"
          onClick={() => void handleOnClickCancel()}
        >
          {t('organizer.shortcut.setting.button.cancel')}
        </Button>
        <Button
          size="medium"
          shape="rect"
          variant="solid"
          colorset="level1"
          onClick={() => saveMutation.mutate()}
        >
          {t('organizer.shortcut.setting.button.save')}
        </Button>
      </div>
    </div>
  );
}

export default SettingsMenu;
