import { mockedLinkPlusList } from '../mocks';
import { WorksLinkPlusListType } from './getWorksList';

export interface Response {
  code: string;
  message: string;
  data: OriginLinkPlusType[];
}

export interface OriginLinkPlusType {
  id: number;
  createdAt: string;
  updatedAt: string;
  partnerId: number;
  partnerName: string;
  categoryId: number;
  categoryName: string;
  categoryCode: string;
  name: string;
  code: string;
  summary: string;
  description: string;
  confirmation: string;
  registrationId: number;
  registrationStatus: string;
  registrationUrl: string;
  registrationReturnPath?: string | null;
  cancelUrl: string;
  cancelReturnPath?: string | null;
  ssoUrl: string;
  directLinkUrl?: string | null;
  linkType: string;
  regApiResultFlag: boolean;
  cancelApiResultFlag: boolean;
  sortOrder: number;
  termsProvided: boolean;
  linkComponentBeanName: unknown;
  guideTitle: string;
  guides: unknown[];
  banners: Banner[];
  exposure: boolean;
  deletedAt: unknown;
  defaultProduct: boolean;
  markNew: boolean;
  productAccessAuthority: boolean;
  recommended: boolean;
  recommendationsCount: number;
  contact?: string | null;
  email?: string | null;
  watch?: string | null;
  regProductName: unknown;
  regProductSummary: unknown;
  regProductBanners: unknown;
  accessAuthority: string;
  accessTarget: unknown;
  companyName: unknown;
  clusterName: unknown;
  registrationApplyDate: string;
  bookmarked: boolean;
}

export interface Banner {
  id: number;
  attach: Attach;
  sortOrder: number;
  bannerName: string;
  bannerType: string;
}

export interface Attach {
  id: number;
  name: string;
  path: string;
  size: number;
  extension: string;
  thumbnail: string;
}

export const QUERY_KEY = 'organizer-link-plus-list';

export function getMockedData() {
  const mockedData = mockedLinkPlusList;
  return mockedData;
}

export async function getLinkPlusList({
  page,
  offset,
}: {
  page: number;
  offset: number;
}): Promise<WorksLinkPlusListType> {
  try {
    const response = await fetch(
      `/api/linkplus/api/link/product/list/my?registrationStatuses=REGISTRATION_COMPLETE`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Referer-Info': window.location.host,
        },
      },
    );

    if (!response.ok) {
      throw new Error('getLinkPlusList 오류 발생.');
    }

    const resData = (await response.json()) as Response;

    return adapter(page, offset, resData.data);
  } catch (error) {
    console.log('getLinkPlusList 오류 발생', error);
    return {
      appPageList: [],
      nextPage: page + 1,
      hasNext: false,
    };
  }
}

function adapter(
  page: number,
  offset: number,
  source: OriginLinkPlusType[],
): WorksLinkPlusListType {
  return {
    nextPage: page + 1,
    // hasNext: source.length < offset ? false : true,
    hasNext: false,
    appPageList: source.map((data) => {
      return {
        id: data.id,
        name: data.name,
        thumbnail: data.banners[0]?.attach.path ?? '',
        desc: data.summary,
        size: 'large',
      };
    }),
  };
}
